import { Alert, Button, FormControl, FormHelperText, Grid, Input, InputLabel, MenuItem, Select, Snackbar } from '@mui/material';
import { Component } from 'react';
import '../../css/loginScreen.css';

import { LoginObject, RouterProps } from '../../utils/appTypes';
import Network from '../../utils/network';
// import BCTicketLogo from '../../images/logos/booking-corner-ticket.png';

import { NetworkLoginResponse } from '../../utils/networkTypes';
import Cookies from 'js-cookie'
import { withRouter } from 'react-router-dom';
import Header from '../all/Header';
import Container from '../all/Container';
import Main from '../all/Main';

interface State {
    login: LoginObject;
    loginResponse?: NetworkLoginResponse;
    loginResponseError?: string;
    chooseSchool: boolean;
}

interface iProps {

}
type Props = iProps & RouterProps;

class LoginScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            login: {
                SchoolId: 0,
                // UserId: 'Controller',
                // Password: 'WebEv*22'
                UserId: '',
                Password: ''
            },
            chooseSchool: false
        }
    }

    onLogin = () => {
        let { login, loginResponse } = this.state;
        const { history } = this.props;
        if (login.SchoolId !== undefined && login.Password && login.Password.length >= 8 && login.UserId && login.UserId.length >= 1) {
            if (loginResponse) {
                if (login.SchoolId === 0) {
                    login.SchoolError = "Veuillez sélectionner un spectacle";
                    this.setState({ login });
                    return;
                }
            }
            Network.login({
                SchoolId: login.SchoolId,
                UserId: login.UserId,
                Password: login.Password
            }).then((r) => {
                if (r.data.ErrorCode) {
                    login.UserIdError = "";
                    login.PasswordError = "";
                    this.setState({ loginResponseError: r.data.Message, login });
                } else {
                    if (r.data.Token) {
                        Cookies.set('token', r.data.Token, { expires: 1 });
                        history.replace("/events");
                    } else {
                        login.UserIdError = undefined;
                        login.PasswordError = undefined;
                        this.setState({ loginResponse: r.data, chooseSchool: true, login });
                    }
                }
                // this.setState({ loginResponse: r.data }, () => console.log("YEA", this.state.loginResponse));
            }).catch((e) => {
                login.UserIdError = "";
                login.PasswordError = "";
                this.setState({ loginResponseError: "Problème de connexion au serveur (CORS).", login });
            });
        } else {
            if (login.Password === undefined || login.Password.length < 8) {
                login.PasswordError = "Minimum 8 caractères"
            }
            if (login.UserId === undefined || login.UserId.length < 1) {
                login.UserIdError = "Veuillez saisir l'identifiant"
            }

            this.setState({ login });
        }
    }

    changeUserId = (e: string) => {
        let { login } = this.state;
        login.UserId = e;
        login.UserIdError = undefined;
        this.setState({ login });
    }

    changePassword = (e: string) => {
        let { login } = this.state;
        login.Password = e;
        login.PasswordError = undefined;
        this.setState({ login });
    }

    closeLoginError = () => {
        this.setState({ loginResponseError: undefined })
    }

    changeSchool = (schoolId: number) => {
        let { login } = this.state;
        login.SchoolId = schoolId;
        this.setState({ login }, () => this.onLogin());
    }

    render() {
        const { login, loginResponseError, loginResponse, chooseSchool } = this.state;
        return (
            <Main>
                <Header />
                <Container verticalCenter>
                    <Grid justifyContent={"center"} alignContent={"center"} container spacing={3} className='__bc-grid-parent'>
                        <Grid item xs={10}>
                            <FormControl disabled={chooseSchool} color={chooseSchool ? "success" : "primary"} variant='standard' fullWidth error={login.UserIdError !== undefined}>
                                <InputLabel htmlFor="__bc-login-userid-input">Nom d'utilisateur</InputLabel>
                                <Input
                                    id="__bc-login-userid-input"
                                    value={login.UserId}
                                    onChange={(e) => this.changeUserId(e.target.value)}
                                />
                                {
                                    login.UserIdError !== undefined &&
                                    <FormHelperText id="bc-login-userid-helper">{login.UserIdError}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xs={10}>
                            <FormControl disabled={chooseSchool} color={chooseSchool ? "success" : "primary"} variant='standard' fullWidth error={login.PasswordError !== undefined}>
                                <InputLabel htmlFor="__bc-login-password-input">Mot de passe</InputLabel>
                                <Input
                                    id="__bc-login-password-input"
                                    type='password'
                                    value={login.Password}
                                    onChange={(e) => this.changePassword(e.target.value)}
                                    onKeyDown={(e) => e.key === "Enter" && this.onLogin()}
                                />
                                {
                                    login.PasswordError !== undefined &&
                                    <FormHelperText id="bc-login-userid-helper">{login.PasswordError}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>

                        {
                            chooseSchool ?
                                <Grid item xs={10}>
                                    <FormControl variant='standard' fullWidth error={login.SchoolError !== undefined}>
                                        <InputLabel id="__bc-events-spectacle-select">Choisir l'organisation</InputLabel>
                                        <Select
                                            labelId="__bc-events-spectacle-select"
                                            value={login.SchoolId === 0 ? '' : login.SchoolId}
                                            label="Choisir l'organisation"
                                            onChange={(e) => this.changeSchool(Number(e.target.value))}
                                        >
                                            {
                                                loginResponse?.Partners?.map(s => <MenuItem key={`bc-login-select-school-${s.SchoolId}`} value={s.SchoolId}>{s.Name}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                :
                                <Grid item xs={8} my={1}>
                                    <Button
                                        size='large'
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={this.onLogin}
                                    >
                                        {loginResponse && loginResponse.Partners?.length > 0 ? "Suivant" : "Se connecter"}
                                    </Button>
                                </Grid>
                        }

                    </Grid>
                </Container>
                <Snackbar open={loginResponseError !== undefined} autoHideDuration={4000} onClose={this.closeLoginError}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                        {loginResponseError}
                    </Alert>
                </Snackbar>
            </Main>
        );
    }
}

export default withRouter(LoginScreen);