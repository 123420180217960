import { Component } from 'react';
import { QrReader } from '@blackbox-vision/react-qr-reader';
import TicketDetailsScreen from './TicketDetailsScreen';
import { Alert, IconButton, Snackbar, TextField, Tooltip, Typography } from '@mui/material';
import Network from '../../utils/network';
import { RouterProps } from '../../utils/appTypes';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie'
import { NetworkCheckItem, NetworkSpectacleItemObject } from '../../utils/networkTypes';
import Main from '../all/Main';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ScanSquareImage from "../../images/scan-square.png"
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';

interface State {
    title: string[];
    result: string;
    manualResult: string;
    qrFound: boolean;
    qrError: string;
    checkItem?: NetworkCheckItem;
    spectacleDetails?: NetworkCheckItem;
    lastCheckItems: NetworkCheckItem[];
}

interface iProps {

}
type Props = iProps & RouterProps;

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'white',
    },
    '& label': {
        color: '#ccc',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#ccc',
        },
        '&:hover fieldset': {
            borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white',
        },
        '& input': {
            color: 'white',
        },
    },
});

class TicketScannerScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            title: [],
            result: '',
            manualResult: '',
            qrFound: false,
            qrError: '',
            lastCheckItems: []
        }
    }

    refreshSpectacleDetails = () => {
        let token = Cookies.get("token");
        let eventId = this.props.match.params.eventId;
        Network.check({
            Token: token ? token : '',
            Ids: eventId?.split(","),
        }).then((r) => {
            if (r.data.ErrorCode) {
                let errorMessage = "Erreur inconnue. Veuillez contacter BookingCorner"
                if (r.data.ErrorCode === 4 || r.data.ErrorCode === 5) {
                    this.logout();
                }
                this.setState({ qrError: errorMessage, qrFound: false, result: '' });
            } else {
                let result: NetworkCheckItem = r.data;
                this.setState({ spectacleDetails: result });
            }
        }).catch((e) => {
            this.setState({ qrError: "Erreur inconnue. Veuillez contacter BookingCorner", qrFound: false, result: '' });
        });

    }

    getSpectacles = () => {
        let token = Cookies.get("token");
        let eventId = this.props.match.params.eventId;
        const date = new Date();
        date.setDate(date.getDate() -7);
        Network.getAllSpectacles({
            Token: token ? token : '',
            From: date.toISOString()
        }).then((r) => {
            if (r.data.ErrorCode !== undefined) {
                if (r.data.ErrorCode === 4 || r.data.ErrorCode === 5) {
                    this.logout()
                }
            } else {
                let data: NetworkSpectacleItemObject[] = r.data;
                let events = eventId ? eventId.split(",") : [];
                let selectedEvents = data.filter(d => events.find(e => e === d.Id)).map(d => d.Name);
                this.setState({ title: selectedEvents });
            }
        }).catch((e) => {
            this.logout()
        });
    }

    componentDidMount() {
        this.getSpectacles();

    }

    logout = () => {
        Cookies.remove("token");
        this.props.history.replace("/")

    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.state.spectacleDetails === undefined || this.state.spectacleDetails === null) {
            this.refreshSpectacleDetails();

        }
        if (!prevState.qrFound && this.state.qrFound) {
            let token = Cookies.get("token");
            let eventId = this.props.match.params.eventId;
            let { spectacleDetails } = this.state;
            if (token && eventId) {
                Network.check({
                    Token: token,
                    Ids: eventId.split(","),
                    Scan: this.state.result
                }).then((r) => {
                    if (r.data.ErrorCode) {
                        let errorMessage = "Erreur inconnue. Veuillez contacter BookingCorner"
                        if (r.data.ErrorCode === 701) {
                            errorMessage = "Billet invalide"
                        } else if (r.data.ErrorCode === 702) {
                            errorMessage = "Billet invalide"
                        } else if (r.data.ErrorCode === 703) {
                            errorMessage = "Billet invalide"
                        } else if (r.data.ErrorCode === 4 || r.data.ErrorCode === 5) {
                            this.logout();
                        }
                        this.setState({ qrError: errorMessage, qrFound: false, result: '' });
                    } else {
                        let result: NetworkCheckItem = r.data;
                        if (result.CheckedAt === "0001-01-01T00:00:00") {
                            result.isValid = true;
                            result.CheckedAt = new Date().toISOString();
                            if (spectacleDetails && spectacleDetails.CountChecked !== undefined && spectacleDetails.CountChecked !== null) {
                                spectacleDetails.CountChecked = result.CountChecked;
                                spectacleDetails.CountReserved = result.CountReserved;
                            }
                        } else {
                            result.isValid = false;
                        }
                        // let instantDate = new Date();
                        // let checkedDate = new Date(`${result.CheckedAt}+01:00`);
                        // console.log("YEAHHH", instantDate.toUTCString(), checkedDate.toUTCString(), (instantDate.getTime()- checkedDate.getTime()) / (1000))
                        this.setState({ checkItem: result, spectacleDetails });
                    }
                }).catch((e) => {
                    this.setState({ qrError: "Erreur inconnue. Veuillez contacter BookingCorner", qrFound: false, result: '' });
                });
            }
        }
    }

    goBack = () => {
        let eventId = this.props.match.params.eventId;
        this.props.history.push(`/events`, {
            spectacleId: eventId ? eventId.split(",") : []
        })

    }

    addLastNetworkItem = (lastNetworkItem: NetworkCheckItem, array: NetworkCheckItem[]) => {
        if (array.length === 5) {
            array.pop();
        }
        array = array.splice(0, 0, lastNetworkItem);
        return array;
    }

    resetQr = () => {
        let { lastCheckItems, checkItem } = this.state;
        if (checkItem?.isValid) {
            this.addLastNetworkItem(checkItem, lastCheckItems);
        }
        this.setState({ result: '', qrFound: false, checkItem: undefined, lastCheckItems });
    }

    render() {
        const { qrFound, qrError, checkItem, lastCheckItems, title, spectacleDetails } = this.state;
        return (
            <>
                {
                    !qrFound || checkItem === undefined ?

                        <Main style={{ position: 'relative' }}>
                            <div className='__bc-ticket-scanner-mask'></div>
                            <div className="__bc-ticket-scanner-mask-hole"></div>
                            {/* <div className="__bc-ticket-scanner-mask-square"></div> */}
                            <img className='__bc-ticket-scanner-mask-square-img' alt="square" src={ScanSquareImage} />
                            <div className='__bc-ticket-scanner-mask-header'>
                                <div>
                                    {
                                        title.map(t => (
                                            <Tooltip title={t} enterTouchDelay={0} arrow followCursor>
                                                <Typography variant="body1" component="span">{t}</Typography>
                                            </Tooltip>
                                        ))
                                    }
                                </div>
                                <div>
                                    <CloseRoundedIcon className="__bc-ticket-scanner-close" titleAccess="Revenir en arrière" onClick={this.goBack} />
                                </div>
                            </div>

                            <div style={{}} className='__bc-ticket-scanner-mask-manual-search'>
                                <CssTextField
                                    label="Entrez le code"
                                    value={this.state.manualResult}
                                    onChange={(e) => this.setState({ manualResult: e.target.value })}
                                    InputProps={{
                                        endAdornment:
                                            <IconButton disabled={this.state.qrFound || this.state.manualResult.length === 0}
                                                onClick={() => {
                                                    let tmp = this.state.manualResult.slice();
                                                    this.setState({ result: tmp, qrFound: true, manualResult: '' })
                                                }}>
                                                <CheckIcon style={{ color: 'white' }} />
                                            </IconButton>
                                    }}
                                />
                            </div>
                            <div className='__bc-ticket-scanner-mask-footer'>
                                <div></div>
                                <div>
                                    <Typography style={{ display: 'flex', justifyContent: 'center' }} align="center" variant="body1" component="p">{spectacleDetails?.CountChecked} / {spectacleDetails?.CountReserved}</Typography>
                                </div>
                                <div></div>
                            </div>
                            <QrReader
                                // ViewFinder={() => <ViewFinderElement /> }
                                constraints={{ facingMode: 'environment' }}
                                onResult={(result, error) => {
                                    if (!!result) {
                                        this.setState({
                                            result: result.getText(),
                                            qrFound: true
                                        })
                                    }

                                    if (!!error) {
                                        console.info(error);
                                    }
                                }}
                                scanDelay={500}
                                containerStyle={{ width: '100%', height: '100%', display: 'flex' }}
                                videoStyle={{ objectFit: 'cover' }}
                            />
                        </Main>
                        :
                        <>
                            <TicketDetailsScreen reset={this.resetQr} checkItem={checkItem} lastCheckItems={lastCheckItems} />
                        </>
                }

                <Snackbar sx={{ zIndex: '2147483647'}} open={qrError !== ''} autoHideDuration={3000} onClose={() => this.setState({ qrError: '' })}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                        {qrError}
                    </Alert>
                </Snackbar>
            </>
        );
    }
}

export default withRouter(TicketScannerScreen);