import { Box, Button, Chip, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Component } from 'react';
import '../../css/loginScreen.css';
import Network from '../../utils/network';
import Cookies from 'js-cookie'
import { NetworkSpectacleItemObject } from '../../utils/networkTypes';
// import QrReader from 'react-qr-reader'
import { withRouter } from 'react-router-dom';
import { RouterProps } from '../../utils/appTypes';
import Header from '../all/Header';
import LogoutIcon from '@mui/icons-material/Logout';
import Container from '../all/Container';
import BCTicketScanner from '../../images/qrscanner.png';
import Main from '../all/Main';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

interface State {
    spectacles?: NetworkSpectacleItemObject[];
    spectacleId: string[];
    isSelectOpen: boolean;
}

interface iProps {

}
type Props = iProps & RouterProps;

class EventsScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            spectacles: undefined,
            spectacleId: [],
            isSelectOpen: false,
        }
    }

    componentDidMount() {
        let spectacleId: string[] | undefined = undefined;
        if (this.props.location.state && this.props.location.state.spectacleId) {
            spectacleId = this.props.location.state.spectacleId;
        }

        this.getSpectacles(spectacleId)
    }

    getSpectacles = (spectacleId?: string[]) => {
        // let { login } = this.state;
        // const { history } = this.props;
        let token = Cookies.get("token");
        const date = new Date();
        date.setDate(date.getDate() -7);
        Network.getAllSpectacles({
            Token: token ? token : '',
            From: date.toISOString()
        }).then((r) => {
            if (r.data.ErrorCode !== undefined) {
                if (r.data.ErrorCode === 4 || r.data.ErrorCode === 5) {
                    this.logout()
                }
            } else {
                if (spectacleId) {
                    this.setState({ spectacles: r.data, spectacleId })
                } else {
                    this.setState({ spectacles: r.data })
                }
            }
        }).catch((e) => {
            this.logout()
        });
    }

    changeSpectacle = (id: string[] | string) => {
        this.setState({ spectacleId: typeof (id) === "string" ? [] : id })
    }

    startScan = () => {
        this.props.history.push(`/event/${this.state.spectacleId}`)
    }

    handleError = (err: string) => {
        console.error(err)
    }

    logout = () => {
        Cookies.remove("token");
        // window.location.reload();
        this.props.history.replace("/")

    }

    render() {
        const { spectacles, spectacleId, isSelectOpen } = this.state;

        return (
            <Main>
                <Header rightSide={<LogoutIcon className="__bc-logout" onClick={() => this.logout()} />} />
                <Container>
                    <Grid container spacing={3} className='__bc-grid-parent'>
                        <Grid item xs={12} className="__bc-grid-child">
                            <Typography align="center" variant="h1" component="h1" gutterBottom>
                                Contrôle des billets
                            </Typography>
                            <img className='__bc-events-qr-img' alt='Booking Corner Ticket' src={BCTicketScanner} />
                            <div>
                                {
                                    spectacles !== undefined && spectacles.length > 0 ?
                                        <FormControl variant='standard' fullWidth>
                                            <InputLabel id="__bc-events-spectacle-select">Choisir l'événement</InputLabel>
                                            <Select
                                                open={isSelectOpen}
                                                onOpen={() => this.setState({ isSelectOpen: true })}
                                                onClose={() => this.setState({ isSelectOpen: false })}
                                                multiple={true}
                                                labelId="__bc-events-spectacle-select"
                                                value={spectacleId}
                                                label="Select the spectacle"
                                                onChange={(e) => this.changeSpectacle(e.target.value)}
                                                // MenuProps={{ classes: { paper: '__bc-events-select' } }}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((value) => {
                                                            let spectacle = spectacles?.find(s => s.Id === value)
                                                            return <Chip key={`bc-events-spectacle-chip-${value}`} label={spectacle ? spectacle.Name : value} />
                                                        })}
                                                    </Box>
                                                )}
                                            >
                                                {
                                                    spectacles.map(s => (
                                                        <MenuItem key={`bc-events-spectacle-select-item-${s.Id}`} value={s.Id}>
                                                            <b>{s.Name}</b><span style={{ marginLeft: '5px' }}>({new Date(s.When).toLocaleString('fr-CH')})</span>
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                        :
                                        <Typography align="center" variant="body1" component="p">{"Aucun événement existant."}</Typography>
                                }
                                <div className={`__bc-events-button-round ${isSelectOpen && spectacles !== undefined && spectacles.length > 2 ? "__bc-events-button-round-show" : ""}`}>
                                    <Button variant="contained" onClick={() => this.setState({ isSelectOpen: false })}>
                                        <CheckRoundedIcon />
                                    </Button>

                                </div>
                                <Button
                                    disabled={spectacleId.length === 0}
                                    style={{ marginTop: '20px' }}
                                    size='large'
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={this.startScan}
                                >
                                    Démarrer le contrôle
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Main>
        );
    }
}

export default withRouter(EventsScreen);