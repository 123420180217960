import { Component, CSSProperties } from 'react';

interface State {
}

interface iProps {
    style?: CSSProperties;
    verticalCenter?: boolean;

}
type Props = iProps;

class Container extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const { style, verticalCenter } = this.props;
        return (
            <div className={`__bc-container ${verticalCenter ? "__bc-container-middle" : ""}`} style={style}>
                {this.props.children}
            </div>
        );
    }
}

export default Container;