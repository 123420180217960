import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button } from '@mui/material';
import { Component } from 'react';
import { NetworkCheckItem } from '../../utils/networkTypes';
import Container from '../all/Container';
import Header from '../all/Header';
import Main from '../all/Main';

interface State {
}

interface iProps {
    checkItem: NetworkCheckItem;
    lastCheckItems: NetworkCheckItem[];
    reset: () => void;

}
type Props = iProps;

class TicketDetailsScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        }
    }


    render() {
        const { checkItem, lastCheckItems } = this.props;
        console.log("YYYY", checkItem)
        return (
            <Main style={checkItem.isValid ? { backgroundColor: '#4caf50' } : { backgroundColor: '#f44336' }}>
                <Header style={checkItem.isValid ? { backgroundColor: '#4caf50' } : { backgroundColor: '#f44336' }} icon={checkItem.isValid ? <CheckCircleIcon style={{ fontSize: '50px', color: 'white' }} /> : <CancelIcon style={{ fontSize: '50px', color: 'white' }} />} />
                <Container style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', maxHeight: '100%', overflow: 'hidden' }}>
                    <div className="__bc-ticket-details-line">
                        <div>
                            {
                                checkItem.isValid ?
                                    <p style={{ textAlign: 'left' }}><b>Billet valide.</b></p>
                                    :
                                    <p style={{ textAlign: 'left' }}><b>Ce billet a déjà été scannée le {new Date(checkItem.CheckedAt).toLocaleString('fr-CH')}</b></p>
                            }
                            <hr />
                            <p><span>Nom: </span><span>{`${checkItem.Firstname} ${checkItem.Name}`}</span></p>
                            <p><span>Catégorie: </span><span>{checkItem.Category} ({checkItem.SubCategory})</span></p>
                            {
                                checkItem.Seat !== undefined &&
                                <p><span>Place: </span><span>{checkItem.Seat ? checkItem.Seat : '-'}</span></p>
                            }
                        </div>

                        {
                            lastCheckItems.length > 0 &&
                            <div>
                                <hr />
                                <p style={{ textAlign: 'left' }}><b>Derniers tickets scannés:</b></p>
                                {
                                    lastCheckItems.map(lastCheckItem => {

                                        return <p>{`${lastCheckItem.Firstname ? lastCheckItem.Firstname : ''} ${lastCheckItem.Name ? lastCheckItem.Name : ''}, ${lastCheckItem.Seat ? lastCheckItem.Seat : ''} (${new Date(lastCheckItem.CheckedAt).toLocaleString('fr-CH')})`}</p>
                                    })
                                }
                            </div>
                        }
                    </div>
                    <Button
                        className="__bc-ticket-details-button-continue"
                        size='large'
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => this.props.reset()}
                    >
                        Continuer
                    </Button>
                </Container>
            </Main>
        );
    }
}

export default TicketDetailsScreen;