import { Component } from 'react';


interface Props {

}

export class NoMatch extends Component<Props> {
    render() {
        return (
            <>
                <h2>Nothing here</h2>
            </>
        );
    }
}

export default NoMatch;