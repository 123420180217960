import { Component, CSSProperties } from 'react';

interface State {
}

interface iProps {
    style?: CSSProperties;

}
type Props = iProps;

class Main extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const { style } = this.props;
        return (
            <div className="__bc-main" style={style}>
                {this.props.children}
            </div>
        );
    }
}

export default Main;