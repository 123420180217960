import { Component } from 'react';
import LoginScreen from './components/login/LoginScreen';
import { NoMatch } from './components/routes/NoMatch';
import Cookies from 'js-cookie'
import { Route, Switch, withRouter } from 'react-router';
import { RouterProps } from './utils/appTypes';
import EventsScreen from './components/event/EventsScreen';
import './css/global.css';
import './css/muiOverride.css';
import TicketScannerScreen from './components/event/TicketScannerScreen';

interface iProps {
}

type Props = iProps & RouterProps;

interface State {
  token?: string;
}

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      token: undefined
    }
  }

  componentDidMount() {
    const { location, history } = this.props;
    let token = Cookies.get("token");
    if (token !== undefined && token.length > 0) {
      this.setState({ token }, () => {
        if (token && token.length > 0 && location.pathname === "/") {
          history.replace("/events")
        } else if ((token === undefined || token.length === 0) && location.pathname !== "/") {
          history.replace("/")
        }
      });
    }else if((token === undefined || token.length === 0) && location.pathname !== "/") {
      history.replace("/")
    }
  }

  componentDidUpdate() {
    const { location, history } = this.props;
    let token = Cookies.get("token");
    if (token !== undefined && token.length > 0 && token !== this.state.token) {
      this.setState({ token }, () => {

        if (token && token.length > 0 && location.pathname === "/") {
          history.replace("/events")
        } else if ((token === undefined || token.length === 0) && location.pathname !== "/") {
          history.replace("/")
        }
      });
    }else if((token === undefined || token.length === 0) && location.pathname !== "/") {
      history.replace("/")
    }
  }

  render() {
    return (
      // <div className="__bc-login-content">
      <>
        <Switch>
          <Route exact path="/" ><LoginScreen /></Route>
          <Route exact path="/events" ><EventsScreen /></Route>
          <Route exact path="/event/:eventId" ><TicketScannerScreen /></Route>
          <Route path="*"><NoMatch /></Route>
        </Switch>
        </>
      // </div>
    );
  }
}

export default withRouter(App);