import { Component, CSSProperties, ReactElement } from 'react';
import '../../css/loginScreen.css';
import BCTicketLogo from '../../images/logos/booking-corner-ticket.png';

interface State {
}

interface iProps {
    leftSide?: ReactElement;
    rightSide?: ReactElement;
    style?: CSSProperties;
    icon?: ReactElement;

}
type Props = iProps;

class Header extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const { leftSide, rightSide, icon, style } = this.props;
        return (
            <div style={{ position: 'relative' }}>
                {/* <svg width="100%" height="130" viewBox="0 0 500 80" preserveAspectRatio="none">
                    <path d="M0,0 L0,55 Q250,80 500,55 L500,0 Z" fill="#bdb9b7" />
                </svg> */}
                {/* <svg width="100%" height="120" viewBox="0 0 500 80" preserveAspectRatio="none">
                    <path d="M0,0 L0,60 Q250,80 500,60 L500,0 Z" fill="#bdb9b7" />
                </svg> */}
                <div className='__bc-header-content' style={style}>
                    {
                        leftSide ?
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>{leftSide}</div>
                            :
                            <div></div>
                    }
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {
                            icon ?
                                icon
                                :
                                <img className='__bc-header-img' alt='Booking Corner Ticket' src={BCTicketLogo} />
                        }
                    </div>
                    {
                        rightSide ?
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><span></span>{rightSide}</div>
                            :
                            <div></div>
                    }
                </div>
            </div>
        );
    }
}

export default Header;